<template>
    <highcharts  class="chart w-full h-full" :options="options" :ref="chartId" :id="chartId"></highcharts>
</template>

<script>
import {reduceBigNumbers} from '../main.js'
import moment from 'moment';

export default {
    props: ['data', 'chartId', 'unit', 'name'],
    data(){
        return{
            options:{
                chart: {
                    type: 'column',
                    backgroundColor:'rgba(0,0,0,0)',
                    margin:[10,10,20,55]
                },
                title: {
                    text: ''
                },
                yAxis:{
                    visible: true,
                    // lineColor: 'rgba(0, 0, 0, .5)',
                    // gridLineColor: 'rgba(0, 0, 0, 0)',
                    title: '',
                    labels:{
                        formatter: function () {
                            const label = this.axis.defaultLabelFormatter.call(this);

                            // Use thousands separator for four-digit numbers too
                            if (/^[0-9]{4}$/.test(label)) {
                                return reduceBigNumbers(this.value, 0);
                            }
                            return label;
                        }
                    }
                },
                xAxis:{
                    type:'datetime',
                    visible: true,
                    lineColor: 'rgba(0, 0, 0, 0)',
                    gridLineColor: 'rgba(0, 0, 0, 0)',
                    tickColor: 'rgba(0, 0, 0, 0)'
                },
                legend:{
                    enabled: false
                },
                credits:{
                    enabled: false
                },
                plotOptions: {
                    column: {
                        borderRadius: 3,
                        pointWidth: 6,
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                tooltip: {
                    formatter: function(){
                        let result = '<span class="uppercase">' + moment(new Date(this.x)).format('DD/MM/yyyy') + '</span><br><span class="font-semibold">' + reduceBigNumbers(this.y) + '</span>';
                        return result

                    }
                },
                series: [{
                    data: this.data,
                    name: this.name,
                    color: '#767824'
                }]
            }
        }
    },
    methods:{
       
        // setData(){
        //     let chart = this.$refs[this.chartId].chart;
        //     chart.series[0].setData(this.arrayOrder);   
        // }
    },
    computed:{
        
    },
    watch:{
        
    },
    mounted(){
        // this.setData();
    }

}
</script>
