<template>
    <highcharts  class="chart w-full h-full" :options="options" ref="chartId" id="chartId"></highcharts>
</template>


<script>
import { state, actions } from '@/store';

export default {
    props:['data'],
    data(){
        return{
            
        }
    },
    methods: {
    },
    computed:{

        options(){
            
            if(!(this.data)){
                return
            }
            return{
                chart:{
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                type: 'pie',
                margin: [0, 0, 0, 0]
                },
                title:{
                    text: ''
                },
                credits:{
                    enabled:false
                },
                plotOptions:{
                    pie: {
                        dataLabels: {
                            enabled: false,
                        },
                        marker:{
                            enabled: false,
                            radius: 0
                        },
                        startAngle: 0,
                        endAngle: 360,
                        innerSize: '85%',
                        center: ['50%', '50%'],
                        size: '100%',
                        animation: true,
                        borderWidth: 5,
                        borderColor: this.mode == 'darkmode' ? '#262F41' : '#fff'
                    }
                },
                tooltip:{
                    enabled: false
                },
                series: [{
                    name: '',
                    data:[
                        // {
                        //     name:'',
                        //     y: this.data[0].Percent
                        // },
                        // {
                        //     name: '',
                        //     y: this.data[1].Percent
                        // },
                        // {
                        //     name: '',
                        //     y: this.data[2].Percent
                        // },
                        // {
                        //     name: '',
                        //     y: this.data[3].Percent
                        // },
                        // {
                        //     name: 'Otros',
                        //     y: 100 - this.data[0].Percent - this.data[1].Percent - this.data[2].Percent - this.data[3].Percent
                        // }
                    ],
                    colors: ['#F48C40', '#69D4ED', '#B077E2', '#FBD967', '#AFA9A9']
                }]
            }
        },
        mode(){
            return state.mode
        }

    },
    mounted(){
         var chart = this.$refs['chartId'].chart;
         var convertData=[];
         var oPerc=0;
         var oData={};
         var flag=false;
         oData.name='Otros';
         oData.y=0;
         for(var i=0;i<this.data.length;i++){
             if(i<4){
                 var s={};
                 s.name=this.data[i].Name;
                 s.y=this.data[i].Percent;
                 convertData.push(s);
             }else{
                 flag=true;
                 oData.y+=this.data[i].Percent
             }
         }
         if(flag){
             convertData.push(oData);
         }
          chart.series[0].setData(convertData);
    },
    watch:{
        
    }
}
</script>
